<template>
  <div class="bt_artist">
    <router-link :to="{name: 'talk', params: {'talk_room': talkRoom.id}}">
      <img :src="talkRoom.artist.image | imageUrl" alt="マスター選択画像">
      <div class="artist_title">{{ talkRoom.name }}
        <div class="artist_date">最終トーク：
          <span v-if="lastMessage">{{ lastMessage.send_at }}</span>
          <span v-else>----</span>
        </div>
      </div>
      <!--            <div class="creditcard_ud">クレジットカードを更新してください</div>-->
    </router-link>
  </div>
</template>

<script>
import moment from 'moment';
import axios from 'axios';
export default {
  name: 'TalkRoomLinkAtHome',
  props: {
    talkRoom: Object,
  },
  data() {
    return {
      lastMessage: null,
    }
  },
  filters: {
    formatDate (val) {
      return moment(val).format('YYYY/MM/DD HH:mm');
    }
  },
  created () {
    this.initialize();
  },
  computed: {
    rootUrl () {
      return `${process.env.VUE_APP_API_ROOT}../`;
    },
    hasJoin () {
      const index = this.$store.state.loginUser.talk_rooms.findIndex(talk_room => {
        return talk_room.id == this.talk_room.id
      });
      return index > -1;
    },
    isExpired () {
      if (!this.talk_room.subscription) return false;
      const now = moment();
      const aMonthAgo = now.subtract(1, 'M');
      return aMonthAgo.isAfter(moment(this.talk_room.subscription.last_paid_at));
    }
  },
  methods: {
    async initialize () {
      const lastMessage = await axios.get(
          `https://${process.env.VUE_APP_CHAT_SERVER}/talk_rooms/${this.talkRoom.id}/last`
      );
      if (lastMessage.data.message) {
        this.lastMessage = lastMessage.data.message;
      }
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
