<template>
  <article id="wrap">
    <AppHeader theme="green">すてきなせかい</AppHeader>
    <section class="contents">
      <div class="contentInner">
        <div v-if="talkRooms.length === 0">
          <p>登録しているマスターはありません</p>
          <div class="bt_form mt_20 mb_30"><router-link :to="{name: 'artist-add'}">マスターを追加</router-link></div>
        </div>
        <TalkRoomLinkAtHome
            class="bt_artist"
            v-for="talkRoom in talkRooms"
            :talkRoom="talkRoom"
            :key="`talk_room-${talkRoom.id}`"></TalkRoomLinkAtHome>
      </div><!--.contentInner-->
    </section><!--.contents-->
    <AppFooter class="foot_green" fixed></AppFooter>
  </article>
</template>

<script>
import AppHeader from '@/components/AppHeader';
import AppFooter from '@/components/AppFooter';
import TalkRoomLinkAtHome from '@/components/TalkRoomLinkAtHome';
export default {
  name: 'WorldList',
  components: {AppHeader, AppFooter, TalkRoomLinkAtHome},
  filters: {
    imageUrl (val) {
      return `${process.env.VUE_APP_API_ROOT}../${val}`;
    }
  },
  computed: {
    talkRooms () {
      return this.$store.state.loginUser.talk_rooms;
    }
  },
  created () {
    // 現状のマスター選択画面は意味がないため他にとばす
    if (this.$store.state.talkRoom.lastReadTalkRoom) {
      this.$router.push({name: 'talk', params:{talk_room: this.$store.state.talkRoom.lastReadTalkRoom}});
    } else {
      if (this.talkRooms.length) {  // まだトークルームに入ったことがないユーザー
        this.$router.push({name: 'talk', params:{talk_room: this.talkRooms[0].id}});
      } else {  // すべてのトークルームから退会したユーザー
        this.$router.push({name: 'mypage'});
      }
    }
  }
}
</script>
